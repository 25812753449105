<template>
  <v-container
    fluid
  >
    <basic-123-stepper :step="1" />

    <basic-card
      :title="cardTitle"
      class="mt-9"
    >
      <app-api-call
        :call-result="callResult"
      />
      <div
        v-if="callResult.finished && !createCaseActivationData.suspended"
      >
        <basic-note-hint v-if="caseType === 'TransactionCase'">
          {{ $i18n.t('$l.hint.fillTheForm') }}
        </basic-note-hint>

        <basic-note-hint v-if="caseType === 'PhysicalPersonCase'">
          {{ $i18n.t('$l.hint.fillTheFormPhysicalPerson') }}
        </basic-note-hint>

        <basic-note-hint v-if="caseType === 'LegalEntityCase'">
          {{ $i18n.t('$l.hint.fillTheForm') }}
        </basic-note-hint>

        <v-form
          @submit.prevent="createNewCase"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- CASENAME -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="caseForm.name"
                      :label="$i18n.t('$l.case.name')"
                      maxlength="64"
                      counter
                      :error-messages="($v.caseForm.name.$dirty && !$v.caseForm.name.required) ? $i18n.t('$l.app.required') : ''"
                      @input="$v.caseForm.name.$touch()"
                      @blur="$v.caseForm.name.$touch()"
                      v-on="on"
                    />
                  </template>
                  <span>{{ '$l.tooltip.caseName' | translate }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="caseType === 'LegalEntityCase' || caseType === 'TransactionCase'"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- DESCRIPTION -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-textarea
                      v-model="caseForm.description"
                      :label="$i18n.t('$l.case.description')"
                      maxlength="512"
                      counter
                      rows="3"
                      v-on="on"
                    />
                  </template>
                  <span>{{ '$l.tooltip.description' | translate }}</span>
                </v-tooltip>
              </v-col>
              <!-- FIRST PARTICIPANT -->
              <v-col
                v-if="caseType === 'PhysicalPersonCase'"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="caseForm.personName"
                      :label="$i18n.t('$l.person.subject')"
                      :error-messages="personNameErrorMessage()"
                      maxlength="64"
                      counter
                      v-on="on"
                      @input="$v.caseForm.personName.$touch()"
                      @blur="$v.caseForm.personName.$touch()"
                    />
                  </template>
                  <span>{{ '$l.tooltip.personName' | translate }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="caseType === 'PhysicalPersonCase'">
              <v-col
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- DESCRIPTION -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-textarea
                      v-model="caseForm.description"
                      :label="$i18n.t('$l.case.description')"
                      maxlength="512"
                      counter
                      rows="3"
                      v-on="on"
                    />
                  </template>
                  <span>{{ '$l.tooltip.description' | translate }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="caseType === 'TransactionCase'">
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- SUM -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="caseForm.transactionValue"
                      type="number"
                      maxlength="12"
                      counter
                      :label="$i18n.t('$l.case.transactionValue')"
                      :error-messages="transactionValueErrorMessage()"
                      @input="$v.caseForm.transactionValue.$touch()"
                      @blur="$v.caseForm.transactionValue.$touch()"
                      v-on="on"
                    />
                  </template>
                  <span>{{ '$l.tooltip.transactionValue' | translate }}</span>
                </v-tooltip>
                <p v-if="caseForm.transactionValue && caseForm.transactionCurrency">
                  {{ $n(caseForm.transactionValue, 'currency', caseForm.transactionCurrency) }}
                </p>
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- CURRENCY -->
                <v-select
                  v-model="caseForm.transactionCurrency"
                  :items="getCurrencyItems"
                  :label="$i18n.t('$l.case.currency')"
                  :error-messages="($v.caseForm.transactionCurrency.$dirty && !$v.caseForm.transactionCurrency.required) ? $i18n.t('$l.app.required') : ''"
                  @input="$v.caseForm.transactionCurrency.$touch()"
                  @blur="$v.caseForm.transactionCurrency.$touch()"
                  @change="currencyChange($event)"
                />
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                cols="12"
              >
                <!-- DATE -->
                <basic-date-input
                  v-model="caseForm.transactionPredictedDate"
                  autocomplete="new-password"
                  :value="caseForm.transactionPredictedDate"
                  :label="$i18n.t('$l.case.transactionDate')"
                  :hint="$i18n.t('$l.case.dateHint')"
                  :transaction-date="true"
                  :error-messages="transactionDateMessage()"
                  @input="$v.caseForm.transactionPredictedDate.$touch()"
                  @blur="$v.caseForm.transactionPredictedDate.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-end">
            <v-btn
              class="primary"
              text
              type="submit"
              :loading="createNewCaseProgress"
            >
              {{ '$l.app.next' | translate }}
            </v-btn>
          </div>
        </v-form>
      </div>
      <div
        v-if="callResult.finished"
      >
        <div v-if="createCaseActivationData.suspended">
          <basic-service-required
            v-if="createCaseActivationData.suspendedActivation"
            title="$l.services.suspendedActivation"
            dclass="greyee error--text"
            label="$l.services.renewPackage"
          />
          <basic-service-required
            v-if="createCaseActivationData.suspendedInvestigations"
            title="$l.services.suspendedInvestigations"
            dclass="greyee error--text"
            push-to="/services/investigation"
            label="$l.services.addInvestigations"
          />
        </div>
      </div>
    </basic-card>
  </v-container>
</template>

<script>
import { required, maxLength, helpers } from 'vuelidate/lib/validators'
const personNameValidation = helpers.regex('alpha', /^[^!@#&()[{}\]:;,?/*]{2,}\s[^!@#&()[{}\]:;,?/*]{2,}$/)

export default {
  name: 'CreateCase',
  props: {
    caseForm: {
      type: Object,
      default: function () {
        return {}
      },
      required: false
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      createCaseActivationData: { suspended: true, suspendedActivation: true, suspendedInvestigations: true },
      createNewCaseProgress: false,
      temp: null,
      visible: null
    }
  },
  computed: {
    getCurrencyItems () {
      return [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ]
    },
    caseType () {
      return this.$route.params.caseType
    },
    cardTitle () {
      return this.$i18n.t('$l.investigation.createCase') + ' ' + this.$i18n.t(`$l.investigation.createCase${this.caseType}`)
    }
  },
  mounted () {
    if (!['LegalEntityCase', 'PhysicalPersonCase', 'TransactionCase'].includes(this.caseType)) {
      this.$router.push('/overview')
    }
    this.caseForm.transactionCurrency = 'EUR'
    this.init()
  },
  validations: {
    caseForm: {
      name: { required },
      transactionValue: { required, maxLength: maxLength(12) },
      transactionCurrency: { required },
      transactionPredictedDate: { required },
      personName: { required, personNameValidation }
    }
  },
  methods: {
    currencyChange (e) {
      this.caseForm.transactionCurrency = e
    },
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.get('endpointsUserProfile/getActivationData')
        .then(r => {
          this.createCaseActivationData = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    },
    transactionValueErrorMessage () {
      if (this.$v.caseForm.transactionValue.$dirty && !this.$v.caseForm.transactionValue.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    transactionDateMessage () {
      if (this.$v.caseForm.transactionPredictedDate.$dirty && !this.$v.caseForm.transactionPredictedDate.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    personNameErrorMessage () {
      var message = ''
      if (this.$v.caseForm.personName.$dirty && !this.$v.caseForm.personName.required) {
        message = this.$i18n.t('$l.app.required')
      }

      if (this.$v.caseForm.personName.$dirty && !this.$v.caseForm.personName.personNameValidation) {
        message = this.$i18n.t('$l.tooltip.personName')
      }
      return message
    },
    createNewCase () {
      this.$v.$touch()
      let newCase = {
        caseId: '',
        type: '',
        userName: '',
        name: '',
        description: '',
        status: '',
        result: {},
        created: '',
        lastModified: '',
        transactionValue: '',
        transactionCurrency: '',
        transactionPredictedDate: '',
        notes: [],
        participants: []
      }
      newCase.type = this.caseType
      newCase.name = this.caseForm.name
      if (this.caseForm.description) {
        newCase.description = this.caseForm.description
      }
      if (this.caseType === 'TransactionCase') {
        if (this.$v.caseForm.name.$invalid || this.$v.caseForm.transactionValue.$invalid || this.$v.caseForm.transactionPredictedDate.$invalid) {
          newCase = null
        } else {
          newCase.transactionValue = this.caseForm.transactionValue
          newCase.transactionCurrency = this.caseForm.transactionCurrency
          newCase.transactionPredictedDate = this.caseForm.transactionPredictedDate
        }
      }
      if (this.caseType === 'PhysicalPersonCase') {
        if (this.$v.caseForm.name.$invalid || this.$v.caseForm.personName.$invalid) {
          newCase = null
        } else {
          let participant = JSON.parse(JSON.stringify(this.$utils.participantDefault))
          participant.name = this.caseForm.personName
          participant.formData.subject = this.caseForm.personName
          participant.type = 'PhysicalPerson'
          newCase.participants.push(participant)
        }
      }
      if (this.caseType === 'LegalEntityCase') {
        if (this.$v.caseForm.name.$invalid) {
          newCase = null
        } else {
          let participant = JSON.parse(JSON.stringify(this.$utils.participantDefault))
          participant.type = 'LegalEntity'
          newCase.participants.push(participant)
        }
      }
      if (newCase === null) {
        return
      }
      this.createNewCaseProgress = true
      this.$xapi.put('case/create', newCase)
        .then(response => {
          if (response.data.type === 'TransactionCase') {
            this.$router.push({ name: 'ProcessCase', params: { caseId: response.data.caseId } })
          } else {
            this.$router.push({ name: 'ProcessCasePerson', params: { caseId: response.data.caseId } })
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.createNewCaseProgress = false
        })
    }
  }
}
</script>
